<template>
  <div class="container">
    <div class="header">
      <div class="content" @click="handleClick">
        <img class="magnifier" src="./btn_serch_grey@2x.png" alt="" />
        <input
          type="text"
          class="input"
          v-model="inputValue"
          ref="inputRef"
          placeholder="请输入搜索关键词"
        />
      </div>
    </div>

    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          finished-text="没有更多了"
          offset="60"
        >
          <!-- 药品列表 循环商品数据 -->
          <DrugAddReduce
            v-for="(item, index) in medicineData"
            :item="item"
            :itemKey="index"
            :orderIndex="index"
            :mode="isApply === 'true' ? 'edit' : 'plain'"
            @onHandleSelect="handleSelect"
            @onHandleInvertSelect="handleInvertSelect"
            @onClickItem="handleClickItem"
          />
        </van-list>
      </van-pull-refresh>
    </div>
    <Cart
      v-if="isApply === 'true'"
      :selectedWareList="selectedWareList"
      @onHandleSelect="handleSelect"
      @onHandleInvertSelect="handleInvertSelect"
      @onHandlConfirm="handleConfirm"
      @clearCart="handleClearCart"
    />
  </div>
</template>

<script>
import Cart from '@/components/cart';
import DrugAddReduce from '@/components/drugAddReduce';
import { getQueryStringArgs } from '@/utils';
// import eventBus from '@/src/eventBus/index.js'
import eventBus from '../../../src/eventBus/index'
import api from '@/api'

export default {
  name: 'selectMedicine',
  components: {
    Cart,
    DrugAddReduce,
  },
  data () {
    return {
      pageNumber: 1,
      inputValue: '',
      isApply: '', // string类型
      refreshing: false,
      loading: false,
      finished: false,
      medicineData: [
      ],
      selectedWareList: []
    }
  },
  created () {
    const queryObj = getQueryStringArgs()
    this.isApply = queryObj.isApply
    this.queryObj = queryObj
    this.selectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList'))
  },
  beforeDestroy () {
    console.log('beforeDestroy');
  },

  beforeRouteLeave (to, from, next) {
    eventBus.$emit('selectedWares', this.selectedWareList);
    next()
  },

  methods: {
    handleClearCart () {
      this.selectedWareList = []
      sessionStorage.removeItem('selectedWareList')
      const copyMedicineData = this.medicineData.map(item => {
        // 当前商品在购物车中
        return {
          ...item,
          goodsNum: 0,
          isSelected: false,
        }
      })
      this.medicineData = copyMedicineData
    },
    async getGoodsList (isRefresh) {
      let res = {}
      if (this.isApply === 'true') {
        res = await api.getApplyGoods({
          cityName: decodeURIComponent(this.queryObj.cityName),
          infusionCenterCode: this.queryObj.infusionCenterCode,
          keywords: "",
          pageNumber: this.pageNumber,
          pageSize: 10
        })
      } else {
        res = await api.getDrugs({
          keywords: "",
          pageNumber: this.pageNumber,
          pageSize: 10,
          productIds: []
        })
      }

      if (+res.code === 0) {
        if (isRefresh) {
          // 是下拉刷新
          this.medicineData = res.data && (res.data.list || [])
          this.refreshing = false
        } else {
          this.medicineData = this.medicineData.concat((res.data && res.data.list) || [])
        }

        this.loading = false
        if (this.isApply === 'true') {
          // 是预约选择药品
          this.medicineData.forEach(item => {
            const findRes = this.selectedWareList.find(item1 => {
              return item1.goodsId === item.goodsId
            })
            if (findRes) {
              // 当前商品在购物车中
              item.goodsNum = findRes.goodsNum
              item.isSelected = true
            }
          })
        }

        // 告诉van-list
        this.loading = false
        const { totalPage, pageNumber, totalNumber } = res.data
        if (Number(pageNumber) < Number(totalPage)) {
          this.pageNumber = this.pageNumber + 1
          this.finished = false
        }

        // 最后一页逻辑
        if (totalPage === pageNumber) {
          this.finished = true
        }

        if (Number(totalNumber) === 0) {
          this.finished = true
        }
      }
    },
    handleConfirm () {
      // sessionStorage.removeItem('selectedWareList')
      this.$router.go(-1)
    },
    handleInvertSelect (item, index) {
      const findIndex = this.medicineData.findIndex(ware => ware.goodsId === item.goodsId)
      const copyWareList = JSON.parse(JSON.stringify(this.medicineData))

      if (findIndex > -1) {
        // 设置选中
        copyWareList[findIndex].goodsNum = copyWareList[findIndex].goodsNum - 1
        copyWareList[findIndex].isSelected = copyWareList[findIndex].goodsNum > 0
        this.medicineData = copyWareList
      }

      const copySelectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")
      const findIndex2 = copySelectedWareList.findIndex(current => current.goodsId === item.goodsId)
      if (findIndex2 > -1) {
        copySelectedWareList[findIndex2].goodsNum = copySelectedWareList[findIndex2].goodsNum - 1
        if (copySelectedWareList[findIndex2].goodsNum === 0) {
          copySelectedWareList[findIndex2].isSelected = false
          copySelectedWareList.splice(findIndex2, 1)
        }
      }
      this.selectedWareList = copySelectedWareList
      // 同步到全局数据中
      sessionStorage.setItem('selectedWareList', JSON.stringify(copySelectedWareList))
    },
    handleSelect (item, index) {
      const findIndex = this.medicineData.findIndex(ware => ware.goodsId === item.goodsId)
      if (findIndex > -1) {
        const copyWareList = JSON.parse(JSON.stringify(this.medicineData))
        copyWareList[findIndex].isSelected = true
        copyWareList[findIndex].goodsNum = copyWareList[findIndex].hasOwnProperty('goodsNum') ? Number(copyWareList[findIndex].goodsNum) + 1 : 1
        this.medicineData = copyWareList
      }

      const copyItem = JSON.parse(JSON.stringify(item))

      if (copyItem.hasOwnProperty('goodsNum')) {
        copyItem.goodsNum = Number(copyItem.goodsNum) + 1
      } else {
        copyItem.goodsNum = 1
      }

      const copySelectedWareList = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")

      // 区分 是否已经有了
      const index1 = copySelectedWareList.findIndex(ite => ite.goodsId === copyItem.goodsId)
      if (index1 > -1) {
        // 已经有了
        copySelectedWareList[index1].goodsNum = copyItem.goodsNum
      } else {
        // 没有
        copySelectedWareList.push(copyItem)
      }
      this.selectedWareList = copySelectedWareList
      // 同步到全局数据中
      sessionStorage.setItem('selectedWareList', JSON.stringify(copySelectedWareList))
    },
    handleClickItem (item) {

      console.log('handleClickItem', console.log('item', item));
      sessionStorage.setItem('homeMedicine', JSON.stringify(item))
      this.$router.go(-1)
    },
    handleClick () {
      console.log(123);
      // alert(1)
      this.$router.push({
        path: '/searchMedicine',
        query: {
          isApply: this.isApply,
          cityName: decodeURIComponent(this.queryObj.cityName),
          infusionCenterCode: this.queryObj.infusionCenterCode
        }
      })
    },
    onRefresh () {
      this.pageNumber = 1;
      this.getGoodsList(true)
    },
    onLoad () {
      this.loading = true
      this.getGoodsList()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 44px;
    padding: 8px 18px 8px 14px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(60, 107, 240, 0.1);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    .content {
      position: relative;
      // width: 301px;
      height: 28px;
      background: #f5f7fa;
      border-radius: 100px;
      .magnifier {
        position: absolute;
        top: 4px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      .input {
        pointer-events: none;
        position: absolute;
        top: 4px;
        left: 40px;
        width: 250px;
        height: 20px;
        border: none;

        background: #f5f7fa;

        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400px;
        line-height: 20px;
      }
    }
  }

  .list {
    // position: fixed;
    padding-top: 44px;
    padding-bottom: 60px;
    width: 100%;
    // height: 500px;
    .medicine_item {
      padding: 15px;
      background: #fff;
      position: relative;
      // border-bottom: 1px solid #ccc;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 10px; /* 这里的值相当于左padding */
        right: 10px; /* 这里的值相当于右padding */
        border-bottom: 1px solid #e5e5e5; /* 设置底部边框 */
      }

      .name {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px;
      }

      .specs {
        font-size: 12px;
        color: #90949e;
        margin-bottom: 10px;
      }

      .provider {
        font-size: 12px;
        color: #90949e;
      }

      .icon_add {
        position: absolute;
        right: 12px;
        bottom: 11px;
        width: 22px;
        height: 22px;
        border: 5px solid transparent;
        background-clip: padding-box;
      }

      .icon_has_added {
        position: absolute;
        right: 66px;
        bottom: 11px;
        width: 22px;
        height: 22px;
        border: 5px solid transparent;
        background-clip: padding-box;
      }

      .number {
        position: absolute;
        right: 39px;
        bottom: 17px;
        width: 32px;
        height: 20px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }
  }
}
</style>